:root {
  --nextui-box-shadow-medium: 0px 0px 15px 0px rgba(0, 0, 0, .03), 0px 2px 30px 0px rgba(0, 0, 0, .08), 0px 0px 1px 0px rgba(0, 0, 0, .3);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: var(--nextui-box-shadow-medium);
}

body .woot-widget-bubble {
  z-index: 100 !important;

}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

/* global */

a {
  color: var(--primary-color);
  font-weight: 700;
}


/* cards */

/* .card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-top: 100px;
} */

.card {
  width: 500px;
  height: 300px;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card-face {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  transform: rotateY(180deg);
}

.card-button {
  background-color: var(--primary-color);
  width: 35px;
  height: 35px;
  /* color: white;
  cursor: pointer; */
}

.card-button:hover {
  background-color: var(--primary-color);
}



input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
}


.lh-11-5 {
  line-height: 11.5px;
}

.fs-header {
  font-size: 50px;
  line-height: 78.02px;
  font-weight: 700;
}

.text-home {
  font-size: 20px;
}

.fs-header-secondary {
  font-size: 48px;
  line-height: 78.02px;
  font-weight: 700;
}

.fs-header-generic {
  font-size: 48px;
  font-weight: 700;
}

.bold-mp {
  font-weight: bolder;
}

.bg-mp-shadow-primary {
  background-color: var(--primary-shadow-color);
}

.bg-mp-shadow-orange {
  background-color: var(--orange-shadow-color);
}

.bg-mp-primary {
  background-color: var(--primary-color);
}

.bg-mp-yellow {
  background-color: var(--yellow-color);
}

.bg-mp-orange {
  background-color: var(--orange-color);
}

.bg-mp-green {
  background-color: var(--green-color);
  color: black !important;
}

.bg-mp-gray {
  background-color: var(--mp-gray);
}

.text-mp-primary {
  color: var(--primary-color);
}

.text-mp-orange {
  color: var(--orange-color);
}

.text-mp-green {
  color: var(--green-color);
}

.mp-title-size {
  font-size: 64px;
}

.mp-big-button {
  color: white;
  border-radius: 70px;
  text-decoration: none;
  font-weight: 700;
  min-width: 200px;
  height: 54px;
  text-align: center;
  transition: background 0.5s;
}

.mp-big-button:hover {
  background: var(--primary-hover-color);
}

.w-320 {
  width: 320px
}

.btn-outline-orange {
  color: var(--orange-color);
  border-radius: 70px;
  text-decoration: none;
  font-weight: 700;
  height: 54px;
  width: 257px;
  text-align: center;
  transition: background 0.5s;
  background-color: transparent;
  border: 1px solid var(--orange-color);
}

.btn-solid-orange {
  background-color: var(--orange-color);
  color: #fff;
  border-radius: 70px;
  text-decoration: none;
  font-weight: 700;
  height: 54px;
  width: 257px;
  text-align: center;
  transition: background 0.5s;

  border: 1px solid var(--orange-color);
}

.btn-outline-green {
  color: var(--green-color);
  border-radius: 70px;
  text-decoration: none;
  font-weight: 700;
  height: 54px;
  width: 257px;
  text-align: center;
  transition: background 0.5s;
  background-color: transparent;
  border: 1px solid var(--green-color);
}

.btn-special {
  background-color: white;
  color: black !important;
  margin-left: 5% !important;
  width: 93% !important;
}

.container-special {
  border: solid 4px !important;
  border-color: #00a9eb !important;
  margin-bottom: 10px;
  border-radius: 30px;
  padding: 5px;
  margin-left: 10px;
}

.container-special-orange {
  border: solid 4px !important;
  border-color: #ff9e25 !important;
  margin-bottom: 10px;
  border-radius: 30px;
  padding: 5px;
  margin-left: 10px;
  width: 620px;
}

.container-special-green {
  border: solid 4px !important;
  border-color: #79d853 !important;
  margin-bottom: 10px;
  border-radius: 30px;
  padding: 5px;
  margin-left: 10px;
  width: 620px;
}

.btn-solid-green {
  background-color: var(--green-color);
  color: black;
  border-radius: 70px;
  text-decoration: none;
  font-weight: 700;
  height: 54px;
  width: 257px;
  text-align: center;
  transition: background 0.5s;

  border: 1px solid var(--green-color);
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: var(--primary-color);
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
  border-radius: 50% 0 0 50%;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #333;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #666;
}

.openbtn-container {
  text-align: right;
  position: fixed;
  top: 10px;
  right: 10px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #333;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  color: #666;
}

#main {
  transition: margin-right 0.5s;
  padding: 20px;
}

.imgLogo {
  width: 200px;
}

.tooltip-inner {
  background-color: #79d853;
  color: black;
}

.tooltip-arrow::before {
  border-bottom-color: #79d853 !important;
}

.btn-pasos {
  color: black;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  font-weight: 700;
  font-size: 23px;
  border: none;
  background-color: #ff9e25;
  margin-bottom: 20px;
}

.btn-pasos2 {
  border-radius: 30px;
  width: 60px;
  height: 60px;
  font-weight: 700;
  font-size: 23px;
  border: none;
  background-color: #79d853;
  margin-bottom: 20px;
}

.column-right-oa {
  text-align: right;
}

.column-left-oa {
  text-align: left;
}

.select-oa-orange {
  border: none;
  margin-bottom: 20px;
  width: 200px;
  color: black;

  height: 56px;
  text-align: center;
  border-radius: 26px;
  display: initial;
  background-color: #ff9e25;
}

.table-oa {
  width: 90%;
  margin: 0 auto;
}

.modal-transfer .modal-content {
  min-height: 100% !important;
}

.modal-payment .modal-content {
  min-height: 100% !important;
}

.modal-payment option {
  font-weight: bold;
}

.modal-payment option:disabled {
  font-weight: unset;
}

.modal-aut {

  max-width: 80% !important;

}

.modal-transfer {
  height: 95%;
  max-width: 45% !important;

}

.modal-payment {
  height: 80%;
  max-width: 40% !important;
  min-height: 90%;
  z-index: 9999 !important;
}

.select-oa-green {
  border: none;
  margin-bottom: 20px;
  width: 200px;
  color: black;

  height: 56px;
  text-align: center;
  border-radius: 26px;
  display: initial;
  background-color: #79d853;
}

.text-70 {
  width: 70%;
}

@media (max-width: 1280px) {

  .text-70 {
    width: 100%;

  }

  .container-special-orange,
  .container-special-green {

    width: 100%;
  }

  .img-pasos {
    display: none;
  }

  .img-pasos2 {
    display: initial;
  }
}

#ratesDesktop {
  display: initial;
  margin-right: 0;
}

#ratesDesktop .row {
  margin-right: 0;
  padding-left: 20px;
}

#ratesMobile .row {
  margin-right: 0;
}

#ratesMobile {
  display: none;
}

#header-aboutus1 {
  display: initial;
}

#header-aboutus2 {
  display: none;
}

.paddin-simu {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.salto {
  display: none;
}

.waybox-modal {
  top: 20px !important;
  height: 90% !important;
}

@media (max-width: 599px) {



  .reference-input {
    width: 100% !important;
  }

  .salto {
    display: initial;
  }


  .paddin-simu {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  /* golbal */
  #ratesDesktop {
    display: none;
  }

  #ratesMobile {
    display: initial;
  }


  #header-aboutus1 {
    display: none;
  }

  #header-aboutus2 {
    display: initial;
  }

  .modal-aut {

    max-width: 100% !important;
  }

  .modal-transfer {
    min-height: 98%;
    max-width: 100% !important;
  }




  .modal-payment {
    min-height: 95%;
    max-width: 100% !important;
  }

  .column-right-oa {
    text-align: center;
  }

  .column-left-oa {
    text-align: center;
  }

  .imgLogo {
    width: 150px;
  }

  .fs-header {
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
  }

  .text-home {
    font-size: 15px;
  }

  .fs-header-secondary {
    font-size: 30px;
    line-height: 78.02px;
    font-weight: 700;
  }

  .fs-header-generic {
    font-size: 30px;
    font-weight: 700;
  }

  .bg-mp-primary {
    background-color: var(--primary-color);
  }

  /* menu */

  .mp-title-size {
    font-size: 46px;
  }

  .sidebar {
    width: 100%;
    height: 0;
    border-radius: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .sidebar a {
    padding: 8px;
  }

  .sidebar.open {
    height: 100%;
    /* Despliega el menú hacia abajo */
  }

  #main {
    margin-right: 0;
  }

  /* accordion */

  .accordion {
    width: 100%;
  }

  .accordion.open {
    width: 100%;
    transition: width 0.7s;
  }

  /* botones tabs */
  .btn-outline-orange {
    color: var(--orange-color);
    border-radius: 70px;
    text-decoration: none;
    font-weight: 700;

    height: 54px;
    text-align: center;
    transition: background 0.5s;
    background-color: transparent;
    border: 1px solid var(--orange-color);
  }

  .btn-solid-orange {
    background-color: var(--orange-color);
    color: #fff;
    border: none;

    height: 54px;
  }

  .btn-outline-green {
    color: var(--green-color);
    border-radius: 70px;
    text-decoration: none;
    font-weight: 700;
    text-align: center;
    transition: background 0.5s;
    background-color: transparent;
    border: 1px solid var(--green-color);

    height: 54px;
  }

  .btn-solid-green {
    background-color: var(--green-color);
    color: #fff;
    border: none;
    height: 54px;
  }


  .reference-card {
    width: 100% !important;
    text-align: left;
    height: unset;
  }
}

.payment-text {
  width: 100%;
}

.texto-simulacion {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 600px) {

  .texto-simulacion {
    width: 50%;
    margin: 0 auto;
  }

  .payment-text {
    width: 100%;
  }

  .sidebar {
    border-radius: 50% 0 0 50%;
  }

  #main {
    margin-right: 0;
    transition: none;
  }


  .sidebar.open {
    width: 60%;
    /* Extiende el menú hasta la mitad de la pantalla */
  }

  #main.open {
    margin-right: 80%;
    /* Desplaza el contenido hacia la izquierda */
  }

  /*accordion*/

  .accordion {
    width: 45rem;
  }

  .accordion.open {
    width: 50rem;
    transition: width 0.7s;
  }

  .accordion {
    font-size: 15px;
    margin: 0.6rem;
  }
}

.meddipay-ce-text-color {
  color: #01587a;
}

.modal-transfer .accordion::after {
  content: unset;
}

.accordion-movements {
  max-width: 100%;
}

.accordion-movements .card {
  height: unset !important;
}

/* Estilos para el menú acordeón */
.accordion {
  cursor: pointer;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;

}

/* .active, .accordion:hover {
  background-color: #ddd;
} */

.panel {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accordion.open p {
  display: none;
}

/* Estilos para los símbolos "+" y "-" */
.accordion:after {
  content: "\002B";
  font-weight: bold;
  float: right;
  padding-right: 20px;
}

.active:after {
  content: "\2212";
}

.container-special .active:after {
  content: "\2212";
  color: #00a9eb;
}

.container-special .accordion:after {
  content: "\002B";
  font-weight: bold;
  float: right;
  padding-right: 20px;
  color: #00a9eb;
}

/* selector de aliado y paciente */
.content {

  margin-top: 20px;
  animation: fadeEffect 0.5s ease-in-out;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.btn-transparent {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

:root {
  --primary-color: #00a9eb;
  --primary-shadow-color: #39a2db70;
  --yellow-color: #fbe232;
  --orange-color: #ff9e25;
  --orange-shadow-color: #ff9d2556;
  --green-color: #79d853;
  --primary-hover-color: #007eb0;
  --mp-gray: #f0f0f0;
}






.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;

  background-color: #F1F6F4;
}



.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838F93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #00A9EB;

  color: #FFF;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3A4649;

  background-color: transparent;

  color: #00A9EB;
}

.cookie-consent-banner__cta:hover {
  background-color: #00A9EB;
  color: white;
  background-color: #00a0df;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838F93;

  background-color: transparent;

  color: #00A9EB;
}


.modal-transfer .card {
  box-shadow: none !important;
}




/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"].price::-webkit-slider-runnable-track {
  background-color: #00a9eb;
  border-radius: 0.5rem;
  height: 12px;
}

/* slider thumb */
input[type="range"].price::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  margin-top: -9px;
  /* Centers thumb on the track */

  /*custom styles*/
  background-color: #00a9eb;
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

input[type="range"].price:focus::-webkit-slider-thumb {
  border: 1px solid #00a9eb;
  outline: 3px solid #00a9eb;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"].price::-moz-range-track {
  background-color: #00a9eb;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"].price::-moz-range-thumb {
  border: none;
  /*Removes extra border that FF applies*/
  border-radius: 0;
  /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #00a9eb;
  height: 2rem;
  width: 1rem;
}

input[type="range"].price:focus::-moz-range-thumb {
  border: 1px solid #00a9eb;
  outline: 3px solid #00a9eb;
  outline-offset: 0.125rem;
}






/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"].month::-webkit-slider-runnable-track {
  background-color: #00a9eb;
  border-radius: 0.5rem;
  height: 12px;
}

/* slider thumb */
input[type="range"].month::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  margin-top: -9px;
  /* Centers thumb on the track */

  /*custom styles*/
  background-color: #00a9eb;
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

input[type="range"].month:focus::-webkit-slider-thumb {
  border: 1px solid #00a9eb;
  outline: 3px solid #00a9eb;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"].month::-moz-range-track {
  background-color: #ff9e25;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"].month::-moz-range-thumb {
  border: none;
  /*Removes extra border that FF applies*/
  border-radius: 0;
  /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #ff9e25;
  height: 2rem;
  width: 1rem;
}

input[type="range"].month:focus::-moz-range-thumb {
  border: 1px solid #ff9e25;
  outline: 3px solid #ff9e25;
  outline-offset: 0.125rem;
}

.waybox-button {
  float: right;
  background-color: unset !important;
  width: 200px;
  font-size: 0px !important;
  line-height: 0px !important;
  height: 50px !important;
  width: 50px !important;
  background-image: url("../public/assets/images/img/WompiImg.png") !important;
  background-size: 50px 50px !important;
  padding: 0px !important;
}

.waybox-button strong {
  font-size: 0px !important;
}

.waybox-button::before {
  display: none !important;
}

.my-loader {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}


.my-loader-container {
  position: relative;
  min-width: 320px;
  min-height: 200px;
  text-align: center;
  padding: 1rem;
}

.reference-input {
  width: 40%;
}

.card {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.reference-card {
  width: 50%;
  text-align: left;
  height: unset;
}

.transfer-button {
  display: inline-block;
  height: 50px;
  line-height: 40px;
  background-color: white;
  border: 0px;
  border-radius: 4px;
  padding: 0px;
  color: white;
  cursor: pointer;

  width: 50px;
}

.transfer-button::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 8px;
  background-image: url('../public/assets/images/icons/bancolombia-seeklogo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
}

.StoreGPS-button {
  display: inline-block;
  height: 50px;
  line-height: 40px;
  background-color: white;
  border: 0px;
  border-radius: 4px;
  padding: 0px;
  color: white;
  cursor: pointer;

  width: 50px;
}

.StoreGPS-button::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 8px;
  background-image: url('../public/assets/images/icons/store_gps.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
}

.ATM-button {
  display: inline-block;
  height: 50px;
  line-height: 40px;
  background-color: white;
  border: 0px;
  border-radius: 4px;
  padding: 0px;
  color: white;
  cursor: pointer;

  width: 50px;
}

.ATM-button::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 8px;
  background-image: url('../public/assets/images/icons/ATM.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
}

.APP-button {
  display: inline-block;
  height: 50px;
  line-height: 40px;
  background-color: white;
  border: 0px;
  border-radius: 4px;
  padding: 0px;
  color: white;
  cursor: pointer;

  width: 50px;
}

.APP-button::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 8px;
  background-image: url('../public/assets/images/icons/APP.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
}